import React from 'react';
import { graphql, PageProps, HeadProps } from 'gatsby';
import { FluidObject } from 'gatsby-image';

import Heading from '../components/heading';
import Layout from '../components/layout';
import SEO from '../components/seo';

type Props = Queries.BlogPostBySlugQuery;

const ArticleTemplate = ({ data }: PageProps<Props>) => {
  const { markdownRemark: post } = data;

  return (
    <Layout>
      <Heading
        title={post?.frontmatter.title ?? ''}
        image={(data.background?.childImageSharp?.fluid as FluidObject) ?? undefined}
      >
        {post?.frontmatter.date && <p className="text-lg leading-8">{post.frontmatter.date}</p>}
        {post?.frontmatter.description && (
          <p className="text-xl leading-8 text-gray-500">{post.frontmatter.description}</p>
        )}
      </Heading>

      <article className="px-4 pb-20 pt-16 sm:px-6 lg:px-8 lg:pb-28 lg:pt-12">
        {/* FIXME: Heading does not render correctly inside article tag */}
        {/*
        <header className="text-lg max-w-prose mx-auto mb-6">
          {post?.frontmatter.date && (
            <p className="text-lg text-gray-700 text-center leading-8">{post.frontmatter.date}</p>
          )}
          {post?.frontmatter.title && (
            <h1 className="mt-2 mb-8 text-3xl text-center leading-8 font-extrabold tracking-tight text-gray-900 sm:text-4xl sm:leading-10">
              {post.frontmatter.title}
            </h1>
          )}
          {post?.frontmatter.description && (
            <p className="text-xl text-gray-500 leading-8">{post.frontmatter.description}</p>
          )}
        </header>
        */}
        <div className="px-4 py-16 sm:px-6 lg:px-8">
          {/* eslint-disable react/no-danger */}
          <section
            className="prose prose-lg prose-indigo mx-auto text-gray-500"
            dangerouslySetInnerHTML={{ __html: post?.html ?? '' }}
          />
          {/* eslint-enable react/no-danger */}
        </div>
      </article>
    </Layout>
  );
};

export const Head = ({ data }: HeadProps<Props>) => {
  const { markdownRemark: post } = data;

  return <SEO title={post?.frontmatter.title ?? ''} />;
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($id: String!, $previousPostId: String, $nextPostId: String) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        description
        date(formatString: "MMMM DD, YYYY")
      }
    }
    previous: markdownRemark(id: { eq: $previousPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    next: markdownRemark(id: { eq: $nextPostId }) {
      fields {
        slug
      }
      frontmatter {
        title
      }
    }
    background: file(relativePath: { eq: "backgrounds/IMG_3658.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 3840) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;
